<template>
  <div class="bg-white z-0 relative">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="open">
      <Dialog class="relative z-40 lg:hidden" @close="open = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>
        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
              <div class="flex px-4 pb-2 pt-5">
                <button type="button" class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400" @click="open = false">
                  <span class="absolute -inset-0.5" />
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Links -->
              <TabGroup as="div" class="mt-2">
                <div class="border-b border-gray-200">
                  <TabList class="-mb-px flex space-x-8 px-4">
                    <Tab as="template" v-for="category in navigation.categories" :key="category.name" v-slot="{ selected }">
                      <button :class="[selected ? 'border-gray-900 text-gray-900' : 'border-transparent text-gray-900', 'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium']">{{ category.name }}</button>
                    </Tab>
                  </TabList>
                </div>
                <TabPanels as="template">
                  <TabPanel v-for="category in navigation.categories" :key="category.name" class="space-y-10 px-4 pb-8 pt-10">
                    <div class="grid grid-cols-2 gap-x-4">
                      <div v-for="item in category.featured" :key="item.name" class="group relative text-sm">
                        <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                          <img :src="item.imageSrc" :alt="item.imageAlt" class="object-cover object-center" />
                        </div>
                        <router-link :to="{ name: item.routeName }" class="mt-6 block font-medium text-gray-900">
                          <span class="absolute inset-0 z-10" aria-hidden="true" />
                          {{ item.name }}
                        </router-link>
                        <p aria-hidden="true" class="mt-1">Shop now</p>
                      </div>
                    </div>
                    <div v-for="section in category.sections" :key="section.name">
                      <p :id="`${category.id}-${section.id}-heading-mobile`" class="font-medium text-gray-900">{{ section.name }}</p>
                      <ul role="list" :aria-labelledby="`${category.id}-${section.id}-heading-mobile`" class="mt-6 flex flex-col space-y-6">
                        <li v-for="item in section.items" :key="item.name" class="flow-root">
                          <router-link :to="{ name: item.routeName }" class="-m-2 block p-2 text-gray-500">{{ item.name }}</router-link>
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div v-for="page in navigation.pages" :key="page.name" class="flow-root">
                  <router-link :to="{ name: page.routeName }" class="-m-2 block p-2 font-medium text-gray-900">{{ page.name }}</router-link>
                </div>
              </div>
              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div class="flow-root">
                  <a href="#" class="-m-2 block p-2 font-medium text-gray-900">Sign in</a>
                </div>
                <div class="flow-root">
                  <a href="#" class="-m-2 block p-2 font-medium text-gray-900">Create account</a>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <header class="relative bg-white">
      <p class="flex h-10 items-center justify-center bg-gray-900 px-4 text-sm font-medium text-white sm:px-6 lg:px-8"><a href="#"><strong class="font-semibold">GeneriCon 2023</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Join us in Denver from June 7 – 9 to see what’s coming next&nbsp;<span aria-hidden="true">&rarr;</span>
      </a></p>
      <nav aria-label="Top" class="relative z-20 bg-white bg-opacity-90 backdrop-blur-xl backdrop-filter">
        <div class="border-b border-gray-200">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 items-center">
              <button type="button" class="relative rounded-md bg-white p-2 text-gray-400 lg:hidden" @click="open = true">
                <span class="absolute -inset-0.5" />
                <span class="sr-only">Open menu</span>
                <Bars3Icon class="h-6 w-6" aria-hidden="true" />
              </button>
              <!-- Logo -->
              <div class="ml-4 flex lg:ml-0">
                <router-link :to="{ name: 'HomePage' }">
                  <span class="sr-only">{{ appName }}</span>
                  <img class="h-8 w-auto" :src="appLogo" :alt="appName" />
                </router-link>
              </div>
              <!-- Flyout menus -->
              <PopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch">
                <div class="flex h-full space-x-8">
                  <Popover v-for="category in navigation.categories" :key="category.name" class="flex" v-slot="{ open }">
                    <div class="relative flex">
                      <PopoverButton :class="[open ? 'border-gray-900 text-gray-900' : 'border-transparent text-gray-700 hover:text-gray-800', 'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out']">{{ category.name }}</PopoverButton>
                    </div>
                    <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                      <PopoverPanel class="absolute inset-x-0 top-full bg-white text-sm text-gray-500">
                        <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                        <div class="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />
                        <!-- Fake border when menu is open -->
                        <div class="absolute inset-0 top-0 mx-auto h-px max-w-7xl px-8" aria-hidden="true">
                          <div :class="[open ? 'bg-gray-200' : 'bg-transparent', 'h-px w-full transition-colors duration-200 ease-out']" />
                        </div>
                        <div class="relative">
                          <div class="mx-auto max-w-7xl px-8">
                            <div class="grid grid-cols-4 gap-4 py-16">
                              <div class="col-span-3 text-sm">
                                <div class="grid grid-cols-4 gap-4">
                                  <div v-for="section in category.sections" :key="section.name">
                                    <template v-if="section.routeName">
                                      <router-link :to="{ name: section.routeName }" :id="`${section.name}-heading`" class="font-medium text-gray-900 hover:text-gray-800">{{ section.name }}</router-link>
                                    </template>
                                    <template v-else>
                                      <p :id="`${section.name}-heading`" class="font-medium text-gray-900">{{ section.name }}</p>
                                    </template>
                                    <ul role="list" :aria-labelledby="`${section.name}-heading`" class="mt-6 space-y-6 sm:mt-4 sm:space-y-4">
                                      <li v-for="item in section.items" :key="item.name" class="flex">
                                        <router-link :to="{ name: item.routeName }" class="hover:text-gray-800">{{ item.name }}</router-link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div class="col-span-1">
                                <div v-for="item in category.featured" :key="item.name" class="group relative text-base sm:text-sm">
                                  <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                    <img :src="item.imageSrc" :alt="item.imageAlt" class="object-cover object-center" />
                                  </div>
                                  <router-link :to="{ name: item.routeName }" class="mt-6 block font-medium text-gray-900">
                                    <span class="absolute inset-0 z-10" aria-hidden="true" />
                                    {{ item.name }}
                                  </router-link>
                                  <p aria-hidden="true" class="mt-1">Shop now</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </PopoverPanel>
                    </transition>
                  </Popover>
                  <router-link :to="{ name: page.routeName }" v-for="page in navigation.pages" :key="page.name" class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">{{ page.name }}</router-link>
                </div>
              </PopoverGroup>
              <div class="ml-auto flex items-center">
                <div class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <router-link :to="{ name: 'LoginPage' }" class="text-sm font-medium text-gray-700 hover:text-gray-800">Bejelentkezés</router-link>
                  <span class="h-6 w-px bg-gray-200" aria-hidden="true" />
                  <router-link :to="{ name: 'RegistrationPage' }" class="text-sm font-medium text-gray-700 hover:text-gray-800">Regisztráció</router-link>
                </div>
                <!-- Search -->
                <div class="flex lg:ml-6" @click="openSearch">
                  <div class="p-2 text-gray-400 hover:text-gray-500">
                    <span class="sr-only">Search</span>
                    <MagnifyingGlassIcon class="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>
                <!-- Cart -->
                <div class="ml-4 flow-root lg:ml-6">
                  <router-link :to="{ name: 'CartPage' }"  class="group -m-2 flex items-center p-2 relative">
                    <ShoppingBagIcon class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    <span class="ml-2 font-medium text-white rounded-full hover:bg-gray-900 bg-gray-600 w-5 h-5 text-center absolute bottom-0 right-0 text-xs leading-5">{{ cartItemCount }}</span>
                    <span class="sr-only">items in cart, view bag</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>

  <SearchModal :open="isSearchModalOpen" @close="closeSearchModal" />
</template>

<script setup>
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const navigation = {
  categories: [
    {
      id: 'kategoriak',
      name: 'Kategóriák',
      featured: [
        {
          name: 'Akció',
          routeName: 'HomePage',
          imageSrc: 'https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg',
          imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
        },
      ],
      sections: [
        {
          id: 'butorok',
          name: 'Bútorok',
          items: [
            { name: 'Ülőbútorok', routeName: 'AboutPage' },
            { name: 'Asztalok', routeName: 'HomePage' },
            { name: 'Székek', routeName: 'HomePage' },
            { name: 'Tárolás', routeName: 'HomePage' },
            { name: 'Kertibútorok', routeName: 'HomePage' },
            { name: 'Előszoba bútorok', routeName: 'HomePage' },
          ],
        },
        {
          id: 'dekoraci',
          name: 'Dekoráció',
          items: [
            { name: 'Képek', routeName: 'HomePage' },
            { name: 'Vázák', routeName: 'HomePage' },
            { name: 'Gyerták és gyertyatartók', routeName: 'HomePage' },
            { name: 'Tükrök', routeName: 'HomePage' },
            { name: 'Design lakáskiegészítők', routeName: 'HomePage' },

            { name: 'Asztali dekorációk', routeName: 'HomePage' },
            { name: 'Mécsesek', routeName: 'HomePage' },
            { name: 'Gyújtók', routeName: 'HomePage' },
            { name: 'Kandallók és kályhák lakáskiegészítők', routeName: 'HomePage' },
            { name: 'Mécses és kandalló üzemanyag', routeName: 'HomePage' },
            { name: 'Mécses védőhúzatok', routeName: 'HomePage' },
          ],
        },
        {
          id: 'vilagitas',
          name: 'Világítás',
          items: [
            { name: 'Függeszték', routeName: 'HomePage' },
            { name: 'Csillárok', routeName: 'HomePage' },
            { name: 'Mennyezeti lámpák', routeName: 'HomePage' },
            { name: 'Spotlámpák', routeName: 'HomePage' },
            { name: 'Fali lámpák', routeName: 'HomePage' },
            { name: 'Állólámpák', routeName: 'HomePage' },
          ],
        },
        {
          id: 'felujitas',
          name: 'Felújítás',
          items: [
            { name: 'Függeszték', routeName: 'HomePage' },
            { name: 'Csillárok', routeName: 'HomePage' },
            { name: 'Mennyezeti lámpák', routeName: 'HomePage' },
            { name: 'Spotlámpák', routeName: 'HomePage' },
            { name: 'Fali lámpák', routeName: 'HomePage' },
            { name: 'Állólámpák', routeName: 'HomePage' },
          ],
        },
        {
          id: 'konyha',
          name: 'Konyha',
          items: [
            { name: 'Függeszték', routeName: 'HomePage' },
            { name: 'Csillárok', routeName: 'HomePage' },
            { name: 'Mennyezeti lámpák', routeName: 'HomePage' },
            { name: 'Spotlámpák', routeName: 'HomePage' },
            { name: 'Fali lámpák', routeName: 'HomePage' },
            { name: 'Állólámpák', routeName: 'HomePage' },
          ],
        },
        {
          id: 'furdoszoba',
          name: 'Fürdőszoba',
          items: [
            { name: 'Függeszték', routeName: 'HomePage' },
            { name: 'Csillárok', routeName: 'HomePage' },
            { name: 'Mennyezeti lámpák', routeName: 'HomePage' },
            { name: 'Spotlámpák', routeName: 'HomePage' },
            { name: 'Fali lámpák', routeName: 'HomePage' },
            { name: 'Állólámpák', routeName: 'HomePage' },
          ],
        }
      ],
    },
  ],
  pages: [
    { name: 'GYIK', routeName: 'FAQPage' },
    { name: 'Kapcsolat', routeName: 'ContactPage' },
  ],
}

const open = ref(false)
</script>

<script>
import { mapGetters } from 'vuex';
import SearchModal from '@/components/SearchModal.vue';

export default {
  name: 'NavbarPage',
  computed: {
    ...mapGetters(['cartItemCount', 'appLogo', 'appName'])
  },
  data() {
    return {
      isSearchModalOpen: false
    }
  },
  methods: {
    openSearch() {
      this.isSearchModalOpen = true;
    },
    closeSearchModal() {
      this.isSearchModalOpen = false;
    },
  },
  watch: {
    isSearchModalOpen(newValue) {
      if (newValue) {
        console.log('A kereső modál megnyílt.');
      } else {
        console.log('A kereső modál bezárult.');
      }
    },
  },
}
</script>