import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    products: [],
    appLogo: 'https://www.homeinfo.hu/assets/homeinfo2017/images/homeinfo-logo-124x54.tudastar.png',
    apiUrl: 'https://api.example.com',
    appName: 'Homeinfo Shop',
    cart: []
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    addToCart(state, product) {
      const existingProduct = state.cart.find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        state.cart.push(product);
      }
    },
    removeFromCart(state, productId) {
      state.cart = state.cart.filter(item => item.id !== productId);
    },
    updateCartQuantity(state, { productId, quantity }) {
      const product = state.cart.find(item => item.id === productId);
      if (product) {
        product.quantity = quantity;
      }
    }
  },
  actions: {
    fetchProducts({ commit }) {
      const products = [/* termékek betöltése */];
      commit('setProducts', products);
    },
    addToCart({ commit }, product) {
      commit('addToCart', product);
    },
    removeFromCart({ commit }, productId) {
      commit('removeFromCart', productId);
    }
  },
  getters: {
    allProducts: state => state.products,
    apiUrl: state => state.apiUrl,
    appName: state => state.appName,
    appLogo: state => state.appLogo,
    cartItems: state => state.cart,
    cartItemCount: state => state.cart.length
  },
  plugins: [createPersistedState()],
});
