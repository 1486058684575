<template>
  <div id="app">
    <NavbarPage v-if="!isDisabledNavbar" />
    <router-view />
    <FooterPage />
  </div>
</template>

<script>
import NavbarPage from './components/Navbar.vue';
import FooterPage from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavbarPage,
    FooterPage
  },
  computed: {
    isDisabledNavbar() {
      return this.$route.name === 'CheckoutPage' || this.$route.name === 'RegistrationPage' || this.$route.name === 'LoginPage';
    }
  }
}
</script>