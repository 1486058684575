import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import i18n from './i18n'
import { createHead } from '@unhead/vue' 

//lazyload
//inter font

const head = createHead()

createApp(App)
  .use(router)
  .use(store) 
  .use(i18n)
  .use(head)
  .mount('#app');